<template>
  <div class="hotel-stay">
    <div class="title">Ваше пребывание:</div>
    <div class="content">
      <div class="date">
        <DateTimeBox :date="new Date(stay.checkInTime)" />
        <div class="seperator" />
        <DateTimeBox :date="new Date(stay.checkOutTime)" />
      </div>
      <div class="seperator" />
      <div class="room title">
        <b class="values">
          <div v-if="stay.room">{{stay.room}}</div>
          <div v-if="stay.accessCode">{{stay.accessCode}}</div>
        </b>

        <div class="seperators">
          <div v-if="stay.room"> - </div>
          <div v-if="stay.accessCode"> - </div>
        </div>

        <div class="titles">
          <div v-if="stay.room">{{$t("hotel.stay.room")}}</div>
          <div v-if="stay.accessCode">{{$t("hotel.stay.accessCode")}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateTimeBox from '@/components/DateTimeBox'
export default {
  props: ['stay'],
  components: { DateTimeBox }
}
</script>

<style lang="scss">
.hotel-stay {
  padding: 0 15px;
  margin: 15px 0;

  >.title {
    margin-bottom: 10px;
  }

  .title {
    font-size: 15px;
    color: rgb(82, 82, 82);
  }
  
  >.content {
    display: flex;
    justify-content: center;
    align-items: stretch;

    >.date {
      display: flex;
      align-items: center;
      justify-content: center;

      > .seperator {
        margin: 0 12px;
        width: 16px;
        height: 3px;
        background-color: rgb(124, 124, 124);
      }
    }
    >.seperator {
      margin: 0 15px;
      width: 1px;
      background-color: rgb(219, 219, 219);
    }
    >.room {
      display: flex;
      justify-content: center;
      align-items: center;

      >.values {
        font-size: 16px;
        text-align: right;
      }
      >.seperators {
        margin: 0 5px;
      }
      >.titles {
        font-size: 15px;
      }
    }
  }
}
</style>