<template>
  <div class="hotel-header" :class="{ hide: hide }" v-rellax>
    <div
      class="background"
      :style="{ backgroundImage: 'url(' + hotel.avatar + ')' }"
    />

    <div class="general">
      <h1 v-if="!hasLogo">{{ hotel.title }}</h1>

      <transition name="fade">
        <div class="logo-wrapper" v-if="hasLogo" v-show="showLogo">
          <img :src="hotel.stayAvatar" />
        </div>
      </transition>

      <div class="info">
        <div class="left">
          <div class="address">{{ hotel.address }}</div>
          <div class="phone" v-if="hotel.phoneNumber">
            <md-icon>call</md-icon>
            <a :href="'tel:' + normalizedPhone(hotel.phoneNumber)">{{
              hotel.phoneNumber
            }}</a>
          </div>
        </div>
        <div class="right">
          <div class="links">
            <a
              v-for="link in links"
              :key="link.url"
              :href="link.url"
              target="_blank"
            >
              <div
                class="logo"
                :style="{ backgroundImage: `url(${link.image})` }"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['hide', 'hotel', 'hideBackBtn'],
  data() {
    return {
      showLogo: false,
      links: [
        {
          url: this.hotel.telegram,
          image: require('@/assets/soc_icons/telegram.png'),
        },
        {
          url: this.hotel.vkontakte,
          image: require('@/assets/soc_icons/vkontakte.png'),
        },
      ].filter(link => link.url),
    }
  },
  mounted() {
    this.showLogo = true
  },
  methods: {
    normalizedPhone(phoneNumber) {
      if (phoneNumber == undefined) return undefined
      return phoneNumber
        .replace(/\(/g, '')
        .replace(/\)/g, '')
        .replace(/ /g, '')
        .replace(/-/g, '')
    },
    openSidebar() {
      this.$store.sidebarOpened = !this.$store.sidebarOpened
    },
    back() {
      this.$router.push('/')
    },
  },
  computed: {
    routerWidth() {
      return this.$store.routerWidth
    },
    sidebarOpened() {
      return this.$store.sidebarOpened
    },
    hasLogo() {
      return !!this.hotel.stayAvatar
    },
  },
}
</script>

<style lang="scss">
.hotel-header {
  background-color: black;
  transition: 0.2s opacity;
  opacity: 1;

  &.hide {
    opacity: 0;
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    opacity: 0.65;
  }

  .general {
    position: relative;
    color: white;
    padding: 0 15px;
    padding-top: 45px;
    padding-bottom: 25px;

    h1 {
      margin-top: 15px;
      text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.4);
      font-weight: 200;
      font-size: 1.9rem;
    }

    .logo-wrapper {
      margin-top: 15px;
      display: flex;
      justify-content: center;

      img {
        filter: brightness(100);
        max-height: 180px;
        max-width: 200px;
      }
    }

    > .info {
      display: flex;
      align-items: flex-end;
      margin-top: 20px;
      gap: 10px;

      > .left {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 10px;
        .address {
          text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);
          font-size: 1rem;
        }

        .phone {
          text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);
          font-size: 1rem;

          a {
            color: rgb(220, 220, 220);
            text-decoration: underline !important;
            transition: 0.2s all;
          }

          .md-icon {
            color: rgb(220, 220, 220);
            margin-right: 5px;
          }
        }
      }
      > .right {
        > .links {
          display: flex;
          gap: 10px;

          > a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 42px;
            height: 42px;
            border-radius: 100%;
            background: rgba(255, 255, 255, 0.8);
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.75);

            > .logo {
              width: 32px;
              height: 32px;
              border-radius: 100%;
              background-size: cover;
              background-position: center;
            }
          }
        }
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
