<template>
  <div class="hotel-buttons">
    <div
      class="button"
      v-if="hotel.externalMessenger"
      @click="window.open(hotel.externalMessenger, '_blank')"
    >
      <img src="https://cncrg.org/img/WhatsappIcon.png" />
      <div class="label">
        {{
          hotel.id == 'd790d9b5-5e6c-4e32-9092-b848bc8aa06e'
            ? $t('hotel.header.askQuestion')
            : $t('hotel.header.message')
        }}
      </div>
    </div>

    <div
      class="button"
      v-if="hotel.chatAvailable && isChatAvailableForUser"
      @click="goChat"
    >
      <md-icon>chat_bubble_outline</md-icon>
      <div class="label">{{ $t('hotel.header.message') }}</div>

      <div class="unread-dot" v-if="hasUnreadDialog" />
    </div>

    <div
      class="button"
      v-if="hotel.tipsUrl"
      @click="window.open(hotel.tipsUrl, '_blank')"
    >
      <md-icon>favorite_border</md-icon>
      <div class="label">{{ $t('hotel.header.tips') }}</div>
    </div>

    <div
      class="button"
      v-if="hotel.checkinAvailable && !hasStay"
      @click="$router.push('/checkin/' + id)"
    >
      <md-icon>how_to_reg</md-icon>
      <div class="label">{{ $t('hotel.header.checkIn') }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['hotel', 'hasStay', 'hasUnreadDialog'],
  data() {
    return {
      window,
      isChatAvailableForUser: true,
    }
  },
  events: {
    authorized() {
      const isAuthorized = () =>
        this.$store.isAuthed ||
        this.$api.defaults.headers.common['x-customer-id'] != null

      this.isChatAvailableForUser = true
      if (isAuthorized()) {
        this.$api.get(`user`).then(res => {
          if (isAuthorized())
            this.isChatAvailableForUser = !res.data.data.crmRelatedUser
        })
      }
    },
  },
  methods: {
    goChat() {
      if (!this.$store.isAuthed) {
        this.$events.emit('do-login-popup')
        return
      }

      this.$router.push(`/chat/${this.hotel.id}`)
    },
  },
}
</script>

<style lang="scss">
.hotel-buttons {
  margin: 15px 10px;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &:has(.button) {
    margin-bottom: 20px;
  }

  .button:last-of-type {
    margin-right: 0;
  }

  .button {
    margin-right: 30px;
    height: 40px;
    width: 170px;
    border: rgb(163, 163, 163) 1px solid;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
    transition: 0.2s all;

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      filter: brightness(0.7);
    }

    .md-icon,
    img {
      margin: 0;
      margin-right: 5px;
    }
    img {
      width: 24px;
      height: 24px;
      filter: brightness(0.56);
    }
    .label {
      font-family: Oswald;
      font-weight: 100;
      font-size: 16px;
      text-align: center;
    }
    .unread-dot {
      pointer-events: none;
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 100%;
      background-color: rgb(225, 35, 5);
      top: 5px;
      right: 5px;
    }
  }
}
</style>
