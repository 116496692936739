<template>
  <div id="hotelView">
    <Loading v-if="loading" />
    <HotelHeader ref="header" :hide="scrolled" :hotel="hotel" v-if="hotel" />
    <HotelTop
      :label="hotel.title"
      :transparent="!scrolled"
      v-if="hotel && ($full || !sidebarOpened)"
    />

    <div class="content" v-if="hotel">
      <DiscloseableDescription :description="hotel.description" :limit="75" />

      <Buttons
        :hotel="hotel"
        :hasStay="!!stay"
        :hasUnreadDialog="hasUnreadDialog"
      />

      <Stay :stay="stay" v-if="stay" />
      <div class="stay-stub" v-else />

      <div class="categories">
        <ServiceCategories :categories="hotel.serviceCategories" />
      </div>
    </div>

    <SalesPopup :hotel="hotel" v-if="hotel" />
  </div>
</template>

<script>
import HotelHeader from '@/components/Hotel/Header'
import HotelTop from '@/components/Hotel/Top'
import DiscloseableDescription from '@/components/DiscloseableDescription'
import Buttons from '@/components/Hotel/Buttons'
import Stay from '@/components/Hotel/Stay'
import SalesPopup from '@/components/Hotel/SalesPopup'
import ServiceCategories from '@/components/ServiceCategories'

export default {
  props: ['id', 'tab'],
  components: {
    ServiceCategories,
    HotelHeader,
    HotelTop,
    Buttons,
    Stay,
    SalesPopup,
    DiscloseableDescription,
  },
  data() {
    return {
      hotel: null,
      stay: null,
      dialog: null,
      scrolled: false,
      localStorage,
      hasSales: false,
      loading: true,
    }
  },
  created() {
    this.updateHotel()
  },
  beforeDestroy() {
    clearInterval(this.dialogInterval)
  },
  events: {
    'update-hotel'() {
      this.updateHotel()
    },
    authorized() {
      this.updateHotel()
    },
  },
  methods: {
    updateHotel() {
      this.loading = true
      this.$api
        .get('hotels/' + this.id)
        .then(res => {
          this.hotel = res.data.data
          this.$events.emit(
            'set-map-position',
            this.hotel.latitude,
            this.hotel.longitude,
          )

          this.loadDialog()
          this.dialogInterval = setInterval(this.loadDialog, 15000)

          if (localStorage.token && !this.$store.overrideToken) this.loadStay()
          if (this.hotel.ga) window.gtag('config', this.hotel.ga)
          this.$store.hotelYa = this.hotel.ya || null
          if (this.hotel.ya) {
            window.ym(this.hotel.ya, 'init', {
              clickmap: true,
              trackLinks: true,
              accurateTrackBounce: true,
            })
          }

          this.$events.emit('log-event', 'hotel_open', {
            hotel: this.id,
            title: res.data.data.title,
          })

          this.$events.emit('set-theme', this.hotel.style ?? 'default')

          this.$store.sendUTMIfNotAlready({
            utmSource: this.$store.isIframe ? 'widget' : 'hotel',
            utmContent: this.id,
          })

          localStorage.lastOpenedHotelId = this.id

          this.loading = false
        })
        .catch(() => {
          this.$router.replace('/')
        })
    },
    loadDialog() {
      if (!this.$store.isAuthed || this.$store.overrideToken) return
      this.$api.get(`user/messages`).then(res => {
        this.dialog = res.data.data.find(dialog => dialog.propertyId == this.id)
      })
    },
    loadStay() {
      this.$api.get(`pms/user-stays/${this.id}/active`).then(res => {
        this.stay = res.data.data

        // this.stay = {room:421,checkInTime: new Date(), checkOutTime: new Date(),
        // accessCode:4221
        // }
      })
    },
    _scroll() {
      if (typeof this.$refs.header === 'undefined') return
      let rect = this.$refs.header.$el.getBoundingClientRect()
      this.scrolled = rect.top < -(rect.height / 3)
    },
  },
  computed: {
    sidebarOpened() {
      return this.$store.sidebarOpened
    },
    hasUnreadDialog() {
      if (!this.dialog) return
      return (
        this.dialog.lastMsg.read == false &&
        this.dialog.lastMsg.msgType == 'CHAT_INCOME'
      )
    },
  },
  watch: {
    id() {
      this.updateHotel()
    },
  },
}
</script>

<style lang="scss" scoped>
#hotelView {
  > .content {
    position: absolute;
    margin-top: -15px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background-color: white;
    width: 100%;
    height: 100%;

    .discloseable-description {
      padding: 15px;
      padding-bottom: 0;
    }

    > .categories {
      position: relative;
      width: 94%;
      margin: 0 auto;
    }

    > .stay-stub {
      height: 15px;
    }
  }
}
</style>
