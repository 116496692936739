<template>
  <div id="hotel-sales-popup">
    <PopupBase :show="show" @close="close" :noHeader="true" :transparent="true">
      <div class="window" v-if="show">
        <div
          class="image"
          :style="{ backgroundImage: `url(${availableSalesService.avatar})` }"
        >
          <div class="close-btn" @click="close">
            <md-icon>close</md-icon>
          </div>
        </div>
        <div class="content">
          <div class="title">{{ availableSalesService.title }}</div>

          <div
            class="description"
            v-if="serviceMiniDescription"
            v-html="serviceMiniDescription"
          />
          <md-progress-spinner
            v-else
            md-mode="indeterminate"
          ></md-progress-spinner>

          <button @click="open">{{ $t('order.more') }}</button>
        </div>
      </div>
    </PopupBase>
  </div>
</template>

<script>
import PopupBase from '@/components/Popups/PopupBase'
export default {
  props: ['hotel'],
  components: { PopupBase },
  data() {
    return {
      show: false,
      service: null,
    }
  },
  created() {
    this.show =
      !!this.availableSalesService &&
      !this.$store.hotelsSalesPopupShowed &&
      !this.$store.isIframe

    if (this.show) this.$store.hotelsSalesPopupShowed = true
  },
  methods: {
    open() {
      this.close()
      this.$router.push('/service/' + this.availableSalesService.id)
    },
    close() {
      this.modifyHotelsClosedSalesPopups(hotelsClosedSalesPopups => {
        hotelsClosedSalesPopups[this.hotel.id].push(
          this.availableSalesService.id,
        )
      })
      this.show = false
    },
    modifyHotelsClosedSalesPopups(callback) {
      const hotelsClosedSalesPopups = this.getHotelClosedSalesPopups()

      callback(hotelsClosedSalesPopups)

      localStorage.hotelsClosedSalesPopups = JSON.stringify(
        hotelsClosedSalesPopups,
      )
    },
    getHotelClosedSalesPopups() {
      const hotelsClosedSalesPopups = localStorage.hotelsClosedSalesPopups
        ? JSON.parse(localStorage.hotelsClosedSalesPopups)
        : {}

      if (!hotelsClosedSalesPopups[this.hotel.id]) {
        hotelsClosedSalesPopups[this.hotel.id] = []
      }

      return hotelsClosedSalesPopups
    },
    loadFullService() {
      if (!this.availableSalesService) {
        this.service = null
        return
      }
      this.$api.get(`services/${this.availableSalesService.id}`).then(res => {
        this.service = res.data.data
      })
    },
  },
  computed: {
    salesServices() {
      return this.hotel.serviceCategories
        .filter(cat => cat.type == 'SALES')
        .flatMap(cat => cat.services)
    },
    availableSalesService() {
      const hotelsClosedSalesPopups = this.getHotelClosedSalesPopups()
      const closedSales = hotelsClosedSalesPopups[this.hotel.id]
      return this.salesServices.find(
        service => !closedSales.includes(service.id),
      )
    },
    serviceDescription() {
      return this.service?.serviceDetails.find(
        detail => detail['@type'] == 'DESCRIPTION',
      )?.description
    },
    serviceMiniDescription() {
      const text = this.$stripHtml(this.serviceDescription)
      if (text == null) return null
      const textLimit = 150
      return text.slice(0, textLimit) + (text.length > textLimit ? '...' : '')
    },
  },
  watch: {
    availableSalesService: {
      immediate: true,
      handler() {
        this.loadFullService()
      },
    },
    show: {
      immediate: true,
      handler() {
        document.body.style.overflow = this.show ? 'hidden' : ''
      },
    },
  },
}
</script>

<style lang="scss">
#hotel-sales-popup > .popup-base > .popup {
  > .window {
    border-radius: 16px;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    min-height: 200px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    overscroll-behavior: none;
    width: 350px;

    @media (max-width: 380px) {
      max-width: 90vw;
    }

    > .image {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      width: 100%;
      height: 300px;
      background-position: center;
      background-size: cover;
      padding: 8px;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;

      > .close-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgb(255, 255, 255, 0.8);
        color: black;
        cursor: pointer;
        padding: 4px;
        border-radius: 100%;
      }
    }
    > .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 16px;
      gap: 10px;

      > .title {
        font-weight: bold;
        font-size: 18px;
        text-align: center;
      }

      > .md-progress-spinner-leave-to {
        display: none;
      }

      > button {
        all: unset;
        cursor: pointer;
        font-size: 15px;
        text-align: center;
        font-weight: 600;
        text-transform: uppercase;
        transition: 0.25s all;
        padding: 6px 10px;
        border-radius: 4px;

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}
</style>
