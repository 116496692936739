<template>
  <div
    class="hotel-top"
    :style="{ width: fixedWidth + 'px' }"
    :class="{ transparent }"
  >
    <div class="left snap">
      <div
        class="back-btn"
        @click="back"
        v-if="!$store.isIframe || transparent"
      >
        <md-icon>{{ $store.isIframe ? 'close' : 'arrow_back' }}</md-icon>
      </div>

      <span class="title" v-if="!transparent">{{ label }}</span>
    </div>

    <div class="right snap">
      <TopLangSelector :white="transparent" />

      <div class="menu-btn" @click="openSidebar" v-show="!$full">
        <fa icon="bars" size="lg" />
      </div>
    </div>
  </div>
</template>

<script>
import TopLangSelector from '@/components/Hotel/TopLangSelector'

export default {
  components: { TopLangSelector },
  props: ['label', 'transparent', 'showBackBtn'],
  data() {
    return {
      fixedWidth: this.$full ? this.routerWidth : window.innerWidth,
    }
  },
  computed: {
    routerWidth() {
      return this.$store.routerWidth
    },
  },
  mounted() {
    let interval = setInterval(() => {
      if (this.routerWidth != 0) {
        this.updateFixedWidth()
        clearInterval(interval)
      }
    }, 1)
  },
  watch: {
    routerWidth() {
      this.updateFixedWidth()
    },
  },
  methods: {
    back() {
      this.$router.push('/')
    },
    updateFixedWidth() {
      this.fixedWidth = this.$full ? this.routerWidth : window.innerWidth
    },
    openSidebar() {
      this.$store.sidebarOpened = !this.$store.sidebarOpened
    },
    _resize() {
      this.updateFixedWidth()
    },
  },
}
</script>

<style lang="scss">
.hotel-top {
  position: fixed;
  z-index: 1;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  background-color: white;
  box-shadow: 0 1px 11px 1px rgba(0, 0, 0, 0.15);
  height: 50px;

  &.transparent {
    background-color: transparent;
    box-shadow: 0 1px 11px 1px rgba(0, 0, 0, 0);
    color: white;
    position: absolute;

    .back-btn {
      .md-icon {
        color: white;
      }
    }
  }

  > .left {
    padding-left: 10px;
  }
  > .snap {
    display: flex;
    align-items: center;

    > * {
      margin-right: 15px;
    }
  }

  .back-btn {
    cursor: pointer;
    .md-icon {
      color: black;
    }
  }

  .menu-btn {
    cursor: pointer;
  }

  .title {
    font-family: Oswald;
    font-weight: 100;
    font-size: 24px;
    position: relative;
  }
}
</style>
